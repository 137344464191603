import { lazy } from "react";

const all_routes = [
  {
    path: '/',
    title: 'Invalid Hash',
    Component: lazy(() => import('./component/OnBoarding/InvalidHash'))
  },
  {
    path: '/:merchant',
    title: 'Merchant not Found',
    Component: lazy(() => import('./component/OnBoarding/MerchantNotFound'))
  },
  {
    path: '/:merchant/:hash',
    title: 'Homepage',
    Component: lazy(() => import('./component/Homepage/Homepage'))
  },
  {
    path: '/:merchant/:hash/category',
    title: 'Category',
    Component: lazy(() => import('./component/Homepage/Category'))
  },
  {
    path: '/:merchant/:hash/category/:id',
    title: 'Single Category',
    Component: lazy(() => import('./component/Homepage/SingleCategory'))
  },
  {
    path: '/:merchant/:hash/checkout',
    title: 'Checkout',
    Component: lazy(() => import('./component/Checkout/Checkout'))
  },
  {
    path: '/:merchant/:hash/confirmation',
    title: 'Confirmation',
    Component: lazy(() => import('./component/Checkout/Confirmation'))
  },
  {
    path: '/:merchant/:hash/checkout-success',
    title: 'Checkout Success',
    Component: lazy(() => import('./component/Checkout/CheckoutSuccess'))
  },
  {
    path: '/:merchant/:hash/my-order',
    title: 'My Order',
    Component: lazy(() => import('./component/Order/MyOrder'))
  },
  {
    path: '/:merchant/:hash/rating',
    title: 'My Order',
    Component: lazy(() => import('./component/Order/Rating'))
  },
  {
    path: '/:merchant/:hash/thank-you',
    title: 'My Order',
    Component: lazy(() => import('./component/Order/ThankYou'))
  }
]

export default all_routes